//react
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

//pages
import Home from './pages/home';
import Sports from './pages/Sports';
import Movies from './pages/Movies';
import Music from './pages/Music';
import NotFound from './pages/NotFound';

//components
import ScrollToTop from './components/ScrollToTop';
import NavBar from './components/NavBar';
import FavoriteMovies from './pages/FavoriteMovies';
import MovieRecommender from './pages/MovieRecommender';

// analytics
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from '@vercel/speed-insights/react';


function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <NavBar />
        <div className="content">
          <Routes>
            <Route index element={<Home />} />
            <Route path="sports" element={<Sports />} />
            <Route path="movies" element={<Movies />}>
              <Route index element={<Navigate to="favorites" replace />} />
              <Route path="favorites" element={<FavoriteMovies />} />
              <Route path="recommender" element={<MovieRecommender />} />
            </Route>
            <Route path="music" element={<Music />} />
            <Route path="*" element={<NotFound />} />
          </Routes> 
        </div>
        <SpeedInsights />
        <Analytics />
      </div>
    </Router>
  );
}

export default App;
