import React, { useState } from 'react';
import generateText from '../services/HuggingInference';

const MovieRecommender = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [recommendation, setRecommendation] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        console.log('Searching for:', searchQuery);
        try {
            const result = await generateText(searchQuery);
            const content = result.choices[0].message.content;
            setRecommendation(content);
        } catch (error) {
            console.error('Error generating recommendation:', error);
            setRecommendation(['Error generating recommendation. Please try again.']);
        }
        setLoading(false);
    };

    const renderRecommendations = () => {
        if (typeof recommendation === 'string') {
            const movies = recommendation.split(/\d+\./).filter(movie => movie.trim() !== '');
            return (
                <>
                    <h3>Recommendations:</h3>
                    <ul className="movie-list">
                        {movies.map((movie, index) => {
                            const [titleYear, description] = movie.split(':').map(part => part.trim());
                            const [title, year] = titleYear.split(/\((\d{4})\)/);
                            return (
                                <li key={index} className="movie-item">
                                    <h4 className="movie-title">
                                        {title.trim()}
                                        {year && <span className="movie-year"> ({year})</span>}
                                    </h4>
                                    <p className="movie-description">{description}</p>
                                </li>
                            );
                        })}
                    </ul>
                </>
            );
        }
        return <p>{recommendation}</p>;
    };

    return (
        <div className="movie-search">
            <h1>Movie Finder</h1>
            <h2>Describe what type of movie you're looking for:</h2>
            <form onSubmit={handleSubmit} className="search-form">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Ex: Sci Fi with a lot of action"
                    required
                />
                <button type="submit" disabled={loading}>
                    {loading ? 'Searching...' : 'Search'}
                </button>
            </form>
            {loading && <img src={'/images/spinner.png'} alt="Loading..." className="loading-spinner" />}
            {!loading && recommendation && (
                <div className="movie-results">
                    {renderRecommendations()}
                </div>
            )}
        </div>
    );
}

export default MovieRecommender;