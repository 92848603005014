import { useState, useEffect, useCallback } from 'react';
import MusicCard from '../components/MusicCard';
import contentfulClient from '../services/ContentfulClient';
import { getTagColor } from '../utils/tagColors';

const Music = () => {
    const [concerts, setConcerts] = useState([]);
    const [filteredConcerts, setFilteredConcerts] = useState([]);
    const [showTopOnly, setShowTopOnly] = useState(false);
    const [selectedVenue, setSelectedVenue] = useState('all');
    const [selectedGenre, setSelectedGenre] = useState('all');
    const [venues, setVenues] = useState([]);
    const [genres, setGenres] = useState([]);
      
    useEffect(() => {
        contentfulClient.getEntries({ 
            content_type: 'concertAttended',
            include: 2,
            order: '-fields.date'
        })
        .then((response) => {
            const formattedConcerts = response.items.map(item => {
                const venueEntry = item.fields.venue;
                const venueName = venueEntry?.fields?.venueName;
                const date = new Date(item.fields.date);
                const imageAsset = item.fields.image?.sys?.id 
                    ? response.includes.Asset.find(asset => 
                        asset.sys.id === item.fields.image.sys.id
                      )
                    : null;
                const tags = item.metadata?.tags?.map(tag => ({
                    id: tag.sys.id,
                    name: tag.sys.id
                        .replace(/(concertType|genre)/, '')
                        .replace(/([A-Z])/g, ' $1')
                        .trim(),
                    color: getTagColor(tag.sys.id)
                })) || [];

                return {
                    id: item.sys.id,
                    headline: item.fields.concertName,
                    venue: venueName,
                    location: venueEntry?.fields?.location,
                    date: date.toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric'
                    }),
                    setlistUrl: item.fields.setlistUrl,
                    showUrl: item.fields.showUrl,
                    color: item.fields.color,
                    image: imageAsset?.fields?.file?.url 
                        ? `https:${imageAsset.fields.file.url}` 
                        : null,
                    imageResolution: imageAsset?.fields?.file?.details?.image 
                        ? `${imageAsset.fields.file.details.image.width}x${imageAsset.fields.file.details.image.height}`
                        : null,
                    tags,
                    isTopShow: tags.some(tag => tag.id === 'concertTypeTopShow'),
                    genres: tags.filter(tag => tag.id.startsWith('genre')).map(tag => tag.name)
                };
            });

            // Extract unique venues and genres
            const uniqueVenues = [...new Set(formattedConcerts.map(concert => concert.venue))];
            const uniqueGenres = [...new Set(formattedConcerts.flatMap(concert => concert.genres))];
            
            setVenues(uniqueVenues);
            setGenres(uniqueGenres);
            setConcerts(formattedConcerts);
            setFilteredConcerts(formattedConcerts);
        })
        .catch(console.error);
    }, []);

    const applyFilters = useCallback(() => {
        let filtered = concerts;

        if (showTopOnly) {
            filtered = filtered.filter(concert => concert.isTopShow);
        }

        if (selectedVenue !== 'all') {
            filtered = filtered.filter(concert => concert.venue === selectedVenue);
        }

        if (selectedGenre !== 'all') {
            filtered = filtered.filter(concert => concert.genres.includes(selectedGenre));
        }

        setFilteredConcerts(filtered);
    }, [concerts, showTopOnly, selectedVenue, selectedGenre]);

    useEffect(() => {
        applyFilters();
    }, [applyFilters]);

    return (
        <div className="content">
            <h2>Concerts Attended</h2>
            <div className="filter-section">
                <div className="filter-buttons">
                    <button 
                        className={`filter-button ${!showTopOnly ? 'active' : ''}`}
                        onClick={() => setShowTopOnly(false)}
                    >
                        All Shows
                    </button>
                    <button 
                        className={`filter-button ${showTopOnly ? 'active' : ''}`}
                        onClick={() => setShowTopOnly(true)}
                    >
                        Top Shows
                    </button>
                </div>
                <div className="dropdown-filters">
                    <select 
                        value={selectedVenue} 
                        onChange={(e) => setSelectedVenue(e.target.value)}
                        className="filter-dropdown"
                    >
                        <option value="all">All Venues</option>
                        {venues.map(venue => (
                            <option key={venue} value={venue}>{venue}</option>
                        ))}
                    </select>
                    <select 
                        value={selectedGenre} 
                        onChange={(e) => setSelectedGenre(e.target.value)}
                        className="filter-dropdown"
                    >
                        <option value="all">All Genres</option>
                        {genres.map(genre => (
                            <option key={genre} value={genre}>{genre}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="music-cards-container">
                {filteredConcerts.map(concert => (
                    <MusicCard 
                        key={concert.id}
                        concert={concert}
                    />
                ))}
            </div>
        </div>
    );
}

export default Music;