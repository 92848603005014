import { Link, Outlet, useLocation } from 'react-router-dom';


const Movies = () => {
    const location = useLocation();
    const isRecommender = location.pathname.includes('/recommender');

    return (
        <div className="movies-page">
            <div className="filter-section">
                <div className="filter-buttons">
                    <Link 
                        to="/movies/favorites"
                        className={`filter-button ${!isRecommender ? 'active' : ''}`}
                    >
                        Brett's Favorite Movies
                    </Link>
                    <Link 
                        to="/movies/recommender"
                        className={`filter-button ${isRecommender ? 'active' : ''}`}
                    >
                        AI Movie Recommender
                    </Link>
                </div>
            </div>
            <div className="movies-page-content">
                <Outlet />
            </div>
        </div>
    )
}

export default Movies;
