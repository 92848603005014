import { NavLink } from 'react-router-dom';

const NavBar = () => {
    return ( 
        <nav className="navbar">
            <NavLink to='/' className='logo'>Brett Baron</NavLink>
            <div className="links">
                <NavLink to='/' className={({ isActive }) => isActive ? 'active' : ''}>Home</NavLink>
                <NavLink to='/music' className={({ isActive }) => isActive ? 'active' : ''}>Music</NavLink>
                <NavLink to='/movies' className={({ isActive }) => isActive ? 'active' : ''}>Movies</NavLink>
                <NavLink to='/sports' className={({ isActive }) => isActive ? 'active' : ''}>Sports</NavLink>
            </div>
        </nav>
    );
}
 
export default NavBar;