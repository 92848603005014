const tagColors = {
    // Concert Types
    NoPriorListening: '#FF6B6B',
    TopShow: '#FFD700',
    // Genres
    Rock: '#FFA726',
    Rap: '#AB47BC',
    IndieAlternative: '#66BB6A',
    Edm: '#29B6F6',
    JamBand: '#45B7D1',
};

export const getTagColor = (tagId) => {
    const cleanTagId = tagId.replace(/(concertType|genre)/, '');
    if (cleanTagId === 'TopShow') {
        return `linear-gradient(135deg, #FFD700, #FFA500)`;
    }
    return tagColors[cleanTagId] || '#1DB954';
}; 