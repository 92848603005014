const theMovieDbList = (listId) => {
    const url = `https://api.themoviedb.org/4/list/${listId}?language=en-US&page=1`;
    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_THE_MOVIE_DB_TOKEN}`
        }
    };

    return fetch(url, options)
        .then(res => res.json())
        .catch(err => {
            console.error(err);
            throw err;
        });
};

export default theMovieDbList;