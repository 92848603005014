import React from 'react';

const MusicCard = ({ concert }) => {
    const {
        headline,
        venue,
        date,
        color = '#1DB954',
        setlistUrl,
        showUrl,
        image,
        imageResolution,
        tags
    } = concert;

    return (
        <div 
            className="music-card"
            style={{ 
                borderColor: color,
                boxShadow: `0 4px 8px ${color}33`
            }}
        >
            {image && (
                <div className="music-card-image">
                    <img 
                        src={image} 
                        alt={`${headline} at ${venue}`}
                        title={`Resolution: ${imageResolution}`}
                    />
                </div>
            )}
            <div className="music-card-content">
                <h3 className="music-card-headline">{headline}</h3>
                {tags && tags.length > 0 && (
                    <div className="music-card-tags">
                        {tags.map(tag => (
                            <div 
                                key={tag.id}
                                className={`music-card-tag ${tag.id === 'concertTypeTopShow' ? 'top-show-tag' : ''}`}
                                style={{ 
                                    background: tag.id === 'concertTypeTopShow' 
                                        ? tag.color  
                                        : tag.color,
                                    fontWeight: tag.id === 'concertTypeTopShow' ? 'bold' : 'normal'
                                }}
                            >
                                {tag.id === 'concertTypeTopShow' && (
                                    <span className="star-icon">⭐</span>
                                )}
                                {tag.name}
                            </div>
                        ))}
                    </div>
                )}
                <div className="music-card-details">
                    <div className="music-card-info">
                        <p className="music-card-venue">{venue}</p>
                        <p className="music-card-date">{date}</p>
                    </div>
                </div>
                <div className="music-card-links">
                    {setlistUrl && (
                        <a 
                            href={setlistUrl} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="music-card-button"
                        >
                            View Setlist
                        </a>
                    )}
                    {showUrl && (
                        <a 
                            href={showUrl} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="music-card-button"
                        >
                            Watch Set
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MusicCard;
