import { chatCompletion } from "@huggingface/inference";

const model = "meta-llama/Llama-3.2-3B-Instruct";
const sysPrompt = `Play the role of a movie recommendation companion.
You are given a description of the type of movie the user is looking for. Try to avoid sharing the obvious choices, but utilize when it's a good fit. Try to help someone find the movie they may have never heard of but will love.
You will respond with only the list of movies and a short (2-3 sentences) description of each. Don't provide any other text, don't put quotes around the movie titles.
provide your response in the following format example:
"1. Movie Title (Year): Description
2. Movie Title (Year): Description"
Based on the following preferences/description, recommend 5 movies:`;

const generateText = async (inputs) => {
  const result = await chatCompletion({
    accessToken: process.env.REACT_APP_HUGGINGFACE_API_KEY,
    model: model,
    messages: [
		{ role: "system", content: sysPrompt + '\n' },
		{ role: "user", content: inputs  },
	],
	temperature: 0.5,
	max_tokens: 500,
	top_p: 0.7
  });
  return result;
};

export default generateText;
