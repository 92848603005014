import { useState, useEffect } from 'react';

const RandomMovie = ({ movies }) => {
    const [randomMovie, setRandomMovie] = useState(null);

    const getRandomMovie = () => {
        let newIndex;
        do {
            newIndex = Math.floor(Math.random() * movies.length);
        } while (randomMovie && movies[newIndex].id === randomMovie.id && movies.length > 1);
        
        setRandomMovie(movies[newIndex]);
    };

    // Close on escape key
    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') setRandomMovie(null);
        };
        
        if (randomMovie) {
            document.addEventListener('keydown', handleEscape);
            return () => document.removeEventListener('keydown', handleEscape);
        }
    }, [randomMovie]);

    // Close on overlay click
    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('random-movie-overlay')) {
            setRandomMovie(null);
        }
    };

    return (
        <>
            <button className="filter-button random-movie-button" onClick={getRandomMovie}>
                Random Favorite Movie
            </button>

            {randomMovie && (
                <div className="random-movie-overlay" onClick={handleOverlayClick}>
                    <div className="random-movie-content">
                        <button className="close-button" onClick={() => setRandomMovie(null)}>×</button>
                        <div className="random-movie-grid">
                            <img 
                                src={`https://image.tmdb.org/t/p/w500${randomMovie.poster_path}`} 
                                alt={randomMovie.title}
                                className="random-movie-poster"
                            />
                            <div className="random-movie-details">
                            <button className="filter-button random-movie-button" onClick={getRandomMovie}>
                                    Find another
                                </button>
                                <h3>{randomMovie.title}
                                    <span className="movie-year">
                                        ({new Date(randomMovie.release_date).getFullYear()})
                                    </span>
                                </h3>
                                <p>{randomMovie.overview}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default RandomMovie;